<template>
  <div class="container">
    <h1>upload employee emails</h1>
    <!-- <form> -->
    <div>
      <input
        type="text"
        id="domain"
        v-model="domain"
        placeholder="mycompany.com"
      />
    </div>
    <div>
      <textarea
        type="email"
        placeholder="employee1@email.com, employee2@email.com, employee3@email.com"
        id="email"
        name="email"
        class="email"
        v-model="users"
        rows="5"
      />
    </div>
    <div>
      <input
        type="password"
        id="passphrase"
        v-model="passphrase"
        placeholder="passphrase"
      />
    </div>
    <div class="inputgroup">
      <input type="radio" id="comma" value="," v-model="seperator" />
      <label for="comma">comma ( , )</label>
      <input type="radio" id="space" value=" " v-model="seperator" />
      <label for="space">space ( _ )</label>
      <input type="radio" id="semicolon" value=";" v-model="seperator" />
      <label for="semicolon">semicolon ( ; )</label>
    </div>
    <div class="button">
      <button
        class="btn block"
        v-if="!splitUsers && domain && users && showupload"
        @click="prepareData"
      >
        Add Users
      </button>
      <button class="btn block" v-show="splitUsers" @click="createUsers">
        Upload
      </button>
    </div>
    <!-- </form> -->
    <div class="verification" v-show="splitUsers && !upload">
      <p>Verify if the below email addresses look ok! and click Upload!</p>
      <p>{{ splitUsers }}</p>
    </div>
    <div class="verification" v-show="upload">
      <p>Upload Successful!</p>
    </div>
  </div>
</template>
<script>
import { domainref } from "@/firebase";

export default {
  name: "Admin",
  components: {},
  data() {
    return {
      users: "",
      splitUsers: null,
      seperator: ",",
      domain: "",
      upload: false,
      employees: {},
      passphrase: "",
    };
  },
  computed: {
    showupload() {
      return this.passphrase == "venzojab2020";
    },
  },
  methods: {
    prepareData() {
      this.users = this.users.replace(/\r?\n|\r/g, "");
      this.splitUsers = this.users.trim().split(this.seperator);
      this.splitUsers.forEach((item) => {
        this.employees[item] = {
          email: item,
          firstdose: false,
          seconddose: false,
          notdue: false,
        };
      });
    },
    createUsers() {
      domainref
        .doc(this.domain)
        .set(this.employees, { merge: true })
        .then(() => {
          this.upload = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputgroup {
  margin: 3rem 1rem;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 768px) {
    justify-content: center;
  }
}
</style>
